.p-10 {
  padding: 10px;
}

.list-panel {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100vh;
  border: 1px solid var(--gray-200);
}
.list-panel > .sort-by {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--gray-200);
  cursor: pointer;
}
.list-panel > .sort-by > .sort-by-title {
  padding: 10px 0 10px 10px;
  color: var(--text-muted);
}
.list-panel > .list-container {
  height: -webkit-fill-available;
  overflow-y: scroll;
}
.list-panel > .list-container > .transaction-row {
  cursor: pointer;
  border-bottom: 1px solid var(--gray-200);
}
.list-panel > .list-container > .transaction-row.active {
  border-left: 6px solid var(--primary);
}
.list-panel > .list-container > .transaction-row > div {
  padding: 4px 10px;
}
.list-panel > .list-container > .transaction-row > div > .bt-label {
  color: var(--gray-500);
}
.list-panel > .list-container > .transaction-row * .reference-value, .list-panel > .list-container > .transaction-row * .account-holder-value {
  font-weight: 600;
}
.list-panel *::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.bt-amount-contianer {
  text-align: end;
}
.bt-amount-contianer > .bt-amount {
  font-size: var(--text-base);
}

.actions-panel {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100vh;
  border: 1px solid var(--gray-200);
  overflow-y: scroll;
}
.actions-panel > .tab-content {
  height: -webkit-fill-available;
}
.actions-panel > .tab-content * .frappe-control[data-fieldname=submit_transaction],
.actions-panel > .tab-content * .btn-primary[data-fieldname=bt_reconcile],
.actions-panel > .tab-content * .btn-primary[data-fieldname=create] {
  float: right;
}
.actions-panel > .tab-content * .dt-scrollable {
  height: calc(100vh - 550px) !important;
}
.actions-panel > .tab-content * .dt-toast {
  display: none !important;
}
.actions-panel *::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.nav-actions-link {
  display: block;
  padding: var(--padding-md) 0;
  margin: 0 var(--margin-md);
  color: var(--text-muted);
}
.nav-actions-link.active {
  font-weight: 600;
  border-bottom: 1px solid var(--primary);
  color: var(--text-color);
}
.nav-actions-link:hover {
  text-decoration: none;
}

.report-summary {
  margin: 0.5rem 0 calc(var(--margin-sm) + 1rem) 0 !important;
}

.reconciliation-summary {
  gap: 0 !important;
}
.reconciliation-summary > .summary-item > .summary-label {
  font-size: var(--text-base);
}
.reconciliation-summary > .summary-item > .summary-value {
  font-weight: 600;
  font-size: 16px;
}

.text-blue {
  color: var(--blue-500) !important;
}

.bank-reco-beta-empty-state {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size: 14px;
  color: var(--gray-600);
}
.bank-reco-beta-empty-state > .btn-primary {
  padding: 0.5rem 1rem !important;
}

.no-transactions {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size: 14px;
  width: 100%;
  color: var(--gray-600);
}
.no-transactions > img {
  margin-bottom: var(--margin-md);
  max-height: 70px;
}

.match-popover-header {
  font-size: var(--text-base);
  color: var(--primary);
  font-weight: 500;
  margin-bottom: 0.5rem;
}